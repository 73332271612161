import React from "react"

/*Material-ui */
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

/*Local files */
import BackDuo from "../../images/Trazado_unido.png"
import Card from "../Cards"

const useStyles = makeStyles(theme => ({
  ContenedorMain: {
    // backgroundColor:'#F0F0F0',
    width: "100%",
    height: "560px",
    "@media (max-width: 720px)": {
      height: "auto",
    },
  },
  Titulo1: {
    color: "#385099",
    marginTop: "2.5rem",
    textAlign: "center",
    font: "700 50px Montserrat",
    marginBottom: "2rem",
    width: "60%",
    "@media (max-width: 720px)": {
      font: "700 30px Montserrat",
    },
  },
  titulo1Sub: {
    color: "#16B1CB",
  },
  textoParrafo: {
    color: "#191919",
    width: "100%",
    marginTop: "2rem",
    fontSize: "35px",
    font: "Light 25px/30px Montserrat",
  },
  contenedorTexto: {
    position: "static",
  },
  contenedorImagen: {
    backgroundImage: `url(${BackDuo})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  ContenedorWhite: {
    height: "200px",
  },
  Titulowhite: {
    color: "#385099",
    marginTop: "1.5rem",
    fontSize: "37px",
    font: "800 30px/37px Montserrat",
  },
  containerCards: {
    marginTop: "1.5rem",
    marginLeft: "2rem",
    justifyContent: "center",
    "@media (max-width: 720px)": {
      justifyContent: "space-around",
      marginLeft: 0,
    },
  },
  gridCard: {
    marginLeft: "2rem",
    "@media (max-width: 720px)": {
      marginLeft: "0rem",
    },
  },
  spacingTop: {
    marginLeft: "2rem",
    "@media (max-width: 720px)": {
      marginTop: "40px",
      marginLeft: "0rem",
    },
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <div>
      <Grid className={classes.ContenedorMain}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          xs={12}
        >
          <Grid
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Typography className={classes.Titulo1}>
              Elije la solución perfecta para tu negocio:
            </Typography>
          </Grid>
          <Grid
            xs={12}
            container
            direction="row"
            className={classes.containerCards}
          >
            <Grid xs={5} md={2} className={classes.gridCard}>
              <Card Tipo="Micro" Nemp="15" Costo="3,900" IdName="MICRO LEAD" />
            </Grid>
            <Grid xs={5} md={2} className={classes.gridCard}>
              <Card
                Tipo="Pequeña"
                Nemp="50"
                Costo="5,850"
                IdName="PEQUEÑA LEAD"
              />
            </Grid>
            <Grid xs={5} md={2} className={classes.spacingTop}>
              <Card
                Tipo="Mediana"
                Nemp="300"
                Costo="11,700"
                IdName="MEDIANA LEAD"
              />
            </Grid>
            <Grid xs={5} md={2} className={classes.spacingTop}>
              <Card
                Tipo="Grande"
                Nemp="600"
                Costo="19,500"
                IdName="GRANDE LEAD"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Footer
