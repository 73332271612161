import React from "react"
import Helmet from "react-helmet"
import SecitonOne from "../components/Planes/SectionOne"
import SectionTwo from "../components/Planes/SectionTwo"
/*Material-ui */
import { Grid } from "@material-ui/core"

import FondoGrade from "../images/20500.svg"

const IndexPage = () => (
  <>
    <Helmet>
      <meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
    </Helmet>
    <Grid
      style={{
        backgroundImage: `url(${FondoGrade})`,
        backgroundSize: "cover",
        marginTop: "-0.5rem",
      }}
    >
      <SecitonOne />
      <SectionTwo />
    </Grid>
  </>
)

export default IndexPage
