import React, { useState } from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Tracer from "../components/StatusTracker"
import FormContact from "./FormContact"

import CloseIcon from "@material-ui/icons/Close"
import { setPlan } from "../utils/LocalStorage"

const useStyles = makeStyles({
  root: {
    width: "201px !important",
    height: "394px",
  },
  titleCard: {
    textAlign: "center",
    color: "#385099",
    fontSize: "30px",
    font: "800 25px/30px Montserrat",
    marginTop: "1rem",
    "@media (max-width: 720px)": {
      fontSize: "25px",
    },
  },
  TextParagraph: {
    color: "#385099",
    textAlign: "center",
    fontSize: "20px",
    "@media (max-width: 720px)": {
      fontSize: "18px",
    },
  },
  Precio: {
    color: "#FFB91C",
    textAlign: "center",
    fontSize: "30px",
    font: "800 25px/30px Montserrat",
  },
  ContainerButton: {
    marginTop: "1rem",
  },
  Header: {
    backgroundColor: "#385099",
    width: "100%",
    borderRadius: "15px 15px 0px 0px",
  },
  HeaderHover: {
    backgroundColor: "#FFB91C",
    width: "100%",
    borderRadius: "15px 15px 0px 0px",
  },
  CardConten: {
    borderRadius: "15px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000029",
    marginLeft: "2rem",
    width: "300px",
    "@media (max-width: 720px)": {
      marginLeft: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  Footer: {
    backgroundColor: "#6184E5",
    height: "9px",
    borderRadius: "0px 0px 15px 15px",
    marginTop: "1.5rem",
    width: "100%",
    "@media (max-width: 720px)": {
      width: "100%",
    },
  },
  FooterHover: {
    backgroundColor: "#FFB91C",
    height: "9px",
    width: "100%",
    marginTop: "3rem",
    borderRadius: "0px 0px 15px 15px",
    "@media (max-width: 720px)": {
      width: "100%",
    },
  },
  ClosseIcon: {
    color: "#4267CB",
    fontSize: 35,
    cursor: "pointer !important",
    marginTop: "0.5rem",
  },
})

const StyledButton = withStyles({
  root: {
    background:
      "transparent linear-gradient(90deg, #4267CB 0%, #16B1CB 100%) 0% 0% no-repeat padding-box",
    borderRadius: "27px",
    border: 0,
    color: "white",
    height: 48,
    width: "11rem",
    padding: "0 30px",
    boxShadow: "0px 3px 6px #0000006A",
    "@media (max-width: 720px)": {
      width: "152px",
      height: "49px",
      borderRadius: "50px",
      font: "800 18px Montserrat",
    },
  },
  label: {
    textTransform: "none",
    fontFamily: "Helvetica",
    font: "400 20px/24px Helvetica Neue",
  },
})(Button)

const CardCorporative = props => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [NumeroEmpleados, setNumeroEmpleados] = useState("")
  const [items, setItems] = useState(false)
  const [openContact, setOpenContact] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const HandreChangeValues = (Nempl, Costo, Tipo) => {
    const InfoPlan = {
      NumeroEmpleados: Nempl,
      Costo,
      Tipo
    }
    setPlan(InfoPlan)
    window.location = '/store'

  }
  const Hover = type => {
    if (open === false) {
      setItems(type)
    }
  }
  return (
    <Grid
      xs={12}
      className={classes.CardConten}
      onMouseOver={() => Hover(true)}
      onMouseLeave={() => Hover(false)}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid
        xs={12}
        className={items ? classes.HeaderHover : classes.Header}
        onMouseOver={() => Hover(true)}
        onMouseLeave={() => Hover(false)}
      >
        &nbsp;
      </Grid>
      <Grid
        xs={12}
        onMouseOver={() => Hover(true)}
        onMouseLeave={() => Hover(false)}
      >
        <Typography className={classes.titleCard}>{props.Tipo}</Typography>
      </Grid>
      <Grid
        xs={11}
        onMouseOver={() => Hover(true)}
        onMouseLeave={() => Hover(false)}
      >
        <p className={classes.TextParagraph}>
          Software en formato Saas para Norma 035 para empresas de hasta{" "}
          <strong>{props.Nemp} empleados</strong>.
        </p>
      </Grid>
      <Grid
        xs={12}
        onMouseOver={() => Hover(true)}
        onMouseLeave={() => Hover(false)}
      >
        <Typography className={classes.Precio}>$ {props.Costo}</Typography>
      </Grid>
      <Grid
        xs={12}
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.ContainerButton}
        onMouseOver={() => Hover(true)}
        onMouseLeave={() => Hover(false)}
      >
        <StyledButton
          onClick={() => setOpenContact(true)}
          id={props.IdName}
        >
          Contratar
        </StyledButton>
      </Grid>
      <FormContact open={openContact} setOpenContact={setOpenContact} />
      <Grid xs={12} className={items ? classes.FooterHover : classes.Footer}>
        &nbsp;
      </Grid>
      <div>
        <Dialog
          open={open}
          onClose={props.Clossemodal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={"md"}
          onClose={handleClose}
        >
          <Grid
            xs={11}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <CloseIcon
              className={classes.ClosseIcon}
              onClick={() => handleClose()}
            />
          </Grid>

          <Tracer
            close={handleClose}
            Nempleados={props.Nemp}
            price={props.Costo}
            Tipo={props.Tipo}
          />
        </Dialog>
      </div>
    </Grid>
  )
}

export default CardCorporative
