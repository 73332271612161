import React, { useState } from "react"

/*Material-ui */
import { Grid, Typography } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { makeStyles, withStyles } from "@material-ui/core/styles"

/*Local files */
import CircleAmarillo from "../../images/circleamarillo.png"
import BackgroundYellow from "../../images/backgroundYellow.png"
import CardCorporative from "../CardCorporative"
import FormContact from "../FormContact"

const useStyles = makeStyles(theme => ({
  ContenedorMain: {
    backgroundImage: `url(${CircleAmarillo})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    marginTop: "1.5rem",
    width: "100%",
    height: "950px",
    "@media (max-width: 720px)": {
      backgroundImage: `url(${BackgroundYellow})`,
      height: "auto",
      backgroundSize: "100% 75%",
      backgroundPosition: "bottom",
      paddingBottom: "80px",
    },
  },
  Titulo1: {
    color: "#385099",
    marginTop: "2.5rem",
    textAlign: "center",
    font: "700 50px Montserrat",
    width: "100%",
    "@media (max-width: 720px)": {
      width: "90%",
      font: "700 30px Montserrat",
    },
  },
  titulo1Sub: {
    color: "#16B1CB",
  },
  textoParrafo: {
    color: "#191919",
    width: "100%",
    marginTop: "2rem",
    fontSize: "35px",
    font: "Light 25px/30px Montserrat",
  },
  contenedorTexto: {
    position: "static",
  },
  contenedorImagen: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  ContenedorWhite: {
    height: "200px",
  },
  Titulowhite: {
    color: "#385099",
    marginTop: "1.5rem",
    fontSize: "37px",
    font: "800 30px/37px Montserrat",
  },
  containerCards: {
    marginTop: "1.5rem",
    marginLeft: "2rem",
    justifyContent: "center",
    height: "400px",
    "@media (max-width: 720px)": {
      height: "auto",
      justifyContent: "space-around",
      marginLeft: 0,
    },
  },
  ParrafoTexto: {
    color: "#385099",
    textAlign: "center",
    fontSize: "20px",
    font: "400 17px/30px Montserrat ",
  },
  containerBut: {
    marginTop: "1rem",
  },
  pequeñitas: {
    color: "#385099",
    marginTop: "5rem",
    font: "700 12px/15px Montserrat",
    "@media (max-width: 720px)": {
      width: "75%",
      textAlign: "center",
    },
  },
  gridCard: {
    marginLeft: "2rem",
    "@media (max-width: 720px)": {
      marginLeft: "0rem",
    },
  },
  spacingTop: {
    marginLeft: "2rem",
    "@media (max-width: 720px)": {
      marginTop: "40px",
      marginLeft: "0rem",
    },
  },
}))

const StyledButton = withStyles({
  root: {
    background:
      "transparent linear-gradient(90deg, #4267CB 0%, #16B1CB 100%) 0% 0% no-repeat padding-box",
    borderRadius: "27px",
    border: 0,
    color: "white",
    height: 48,
    width: "194px",
    padding: "0 30px",
    boxShadow: "0px 3px 6px #0000006A",
    "@media (max-width: 720px)": {
      height: 60,
      width: "240px",
      borderRadius: "50px",
      font: "800 18px Montserrat",
    },
  },
  label: {
    textTransform: "none",
    fontFamily: "Helvetica",
    font: "400 20px/24px Helvetica Neue",
  },
})(Button)

const Footer = () => {
  const classes = useStyles()
  const [openContact, setOpenContact] = useState(false)

  return (
    <div>
      <Grid className={classes.ContenedorMain}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          xs={12}
        >
          <Grid
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Typography className={classes.Titulo1}>Corporativos:</Typography>
          </Grid>
          <Grid
            xs={12}
            container
            direction="row"
            className={classes.containerCards}
          >
            <Grid xs={5} md={2} className={classes.gridCard}>
              <CardCorporative
                Tipo="Corp 1000"
                Nemp="1000"
                Costo="29,250"
                IdName="1MIL LEAD"
              />
            </Grid>
            <Grid xs={5} md={2} className={classes.gridCard}>
              <CardCorporative
                Tipo="Corp 5000"
                Nemp="5000"
                Costo="48,750"
                IdName="5MIL LEAD"
              />
            </Grid>
            <Grid xs={5} md={2} className={classes.spacingTop}>
              <CardCorporative
                Tipo="Corp 10000"
                Nemp="10000"
                Costo="58,500"
                IdName="10MIL LEAD"
              />
            </Grid>
            <Grid xs={5} md={2} className={classes.spacingTop}>
              <CardCorporative
                Tipo="Corp 15000"
                Nemp="15000"
                Costo="78,975"
                IdName="15MIL LEAD"
              />
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Typography className={classes.Titulo1}>
              Más de 15,000 empleados
            </Typography>
            <p className={classes.ParrafoTexto}>
              Si este es tu caso, escríbenos y nos pondremos en contacto para
              platicar contigo y ofrecerte una <br />
              solución a medida.
            </p>
            <Grid
              xs={12}
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.containerBut}
            >
              <StyledButton onClick={() => setOpenContact(true)}>
                Contactar
              </StyledButton>
            </Grid>
            <Typography className={classes.pequeñitas}>
              Los precios publicados son antes de impuestos en M.N. Modalidad de
              Pago ANUAL Formato SaaS. Vigencia hasta 31/12/2024
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <FormContact open={openContact} setOpenContact={setOpenContact} />
    </div>
  )
}

export default Footer
